import React, { useState } from 'react';
import {
    Link
} from "react-router-dom";
// import TypeWriterEffect from 'react-typewriter-effect';
// import Typewriter from 'typewriter-effect';



import './LandingPage.css';

function LandingPage() {


    return (

        <div className="landingPage-container d-flex flex-column align-items-center justify-content-center">
            <div className="w-100 p-2 nav-container">
                <div className="navbar text-light p-2 navwidth">
                    <div className="d-flex shark-capital">
                        <div className="mx-1 shark">SHARK</div>
                        <div className="capital">CAPITAL</div>
                    </div>
                    <div>
                        <a className="text-light mx-3" href="#">Our Focus</a>
                        <a className="text-light mx-3" href="https://invsto.com/">Visit Invsto</a>
                    </div>
                </div>
            </div>
            <div className="mb-5 pb-5 container d-flex flex-column align-items-center h-100 justify-content-center">
                <div className="m-4 powered h6">POWERED BY SHARK SIGMA</div>
                <div className="m-4 text-light h1 invest">Investing in the digital age</div>
                <div className="m-4 text-light h4">We invest in data driven strategies in disruptive tech and Web 3.0</div>
                <div className="m-4 d-flex flex-wrap">
                    <div className="m-2 mx-5 h4 strategy">QUANTITATIVE</div>
                    <div className="m-2 h4 text-light line"></div>
                    <div className="m-2 mx-5 h4 strategy">DEEP TECH</div>
                    <div className="m-2 h4 text-light line"></div>
                    <div className="m-2 mx-5 h4 strategy">DIGITAL ASSETS</div>
                </div>
            </div>
        </div>

    )
}


export default LandingPage;