import { Route } from "react-router-dom";
import { useState } from "react";
import LandingPage from "./loginModules/LandingPage";

function App() {
  return (
    <div className="App">
      <LandingPage />
      {/* <Route path="/h" exact component={LandingPage} /> */}
    </div>
  );
}

export default App;
